<div class="unmapped-nodes-list hidden lg:flex">
  <app-nodes-filter class="mb-4"
    [showFields]="filterFields"
    [filterForm]="filterForm"
    (filterChange)="onFilterChanged($event)"
    (refreshNodeListEmitter)="refreshNodeList($event)">
  </app-nodes-filter>

  @if(dataSource.isLoading$ | async) {
    <div class="mat-spinner spinner-container">
      <mat-spinner diameter="80" strokeWidth="8"></mat-spinner>
    </div>
  }
  <ng-container class="flex-1 overflow-auto">
    <mat-table
      (wheel)="handleScroll($event)"
      cdkDropList
      cdkDropListSortingDisabled
      matSort
      [dataSource]="dataSource"
      (matSortChange)="sortChanged($event)"
      class="mat-elevation-z8 nodes-table">
      <ng-container matColumnDef="address">
        <mat-header-cell class="mat-column-header center" *matHeaderCellDef> Node Address </mat-header-cell>
        <mat-cell class="mat-row-cell node-address center" *matCellDef="let nodeInfo">
          <div [attr.data-cy]="'unmapped-node-address-' + (nodeInfo.address || '').toString(16)">
            {{ (nodeInfo.address || '').toString(16) }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="nodeType">
        <mat-header-cell class="mat-column-header center" *matHeaderCellDef> Node Type </mat-header-cell>
        <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
          <div [attr.data-cy]="'unmapped-node-type-' + (nodeInfo.address || '').toString(16)">
            {{ getNodeTypeText(nodeInfo) }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="gatewayName">
        <mat-header-cell
          mat-sort-header
          sortActionDescription="Sort by Gateway Name"
          class="mat-column-header center"
          *matHeaderCellDef>
          Gateway Name
        </mat-header-cell>
        <mat-cell class="mat-row-cell gateway-name center" *matCellDef="let nodeInfo">
          <div [attr.data-cy]="'unmapped-node-gateway-' + (nodeInfo.address || '').toString(16)">
            {{ nodeInfo.gatewayName }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="blinkButton">
        <mat-header-cell class="mat-column-header center" *matHeaderCellDef> Blink Button </mat-header-cell>
        <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
          <button
            mat-mini-fab class="or-button or-primary blink-button"
            [disabled]="isBlinkDisabled(nodeInfo)"
            (click)="blinkClicked(nodeInfo)"
            [attr.data-cy]="'unmapped-node-blink-button-' + (nodeInfo.address || '').toString(16)">
            <span class="or-icon or-small or-icon-light-level"></span>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="draggableNode">
        <mat-header-cell class="mat-column-header center" *matHeaderCellDef> Draggable Node </mat-header-cell>
        <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
          <div class="icon-container" [ngClass]="getContainerClass(nodeInfo)"
               cdkDrag
               [cdkDragDisabled]="isNodeDisabled(nodeInfo)"
               [cdkDragData]="nodeInfo">
            <span
              class="or-icon or-tiny icon-span"
              [ngClass]="getIconClass(nodeInfo)"
              [attr.data-cy]="'unmapped-draggable-node-' + (nodeInfo.address || '').toString(16)">
            </span>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"
               [matTooltip]="getTooltipText(row)"
               [matTooltipDisabled]="!isNodeDisabled(row)"
               [matTooltipPosition]="'right'"
               [ngClass]="getRowClass(row)"></mat-row>
    </mat-table>
  </ng-container>
</div>
<div class="unmapped-nodes-list flex lg:hidden">
  <app-nodes-filter class="mb-4"
                    [showFields]="filterFields"
                    [filterForm]="filterForm"
                    (filterChange)="onFilterChanged($event)"
                    (refreshNodeListEmitter)="refreshNodeList($event)"></app-nodes-filter>
  @if(dataSource.isLoading$ | async) {
    <div class="mat-spinner spinner-container">
      <mat-spinner diameter="80" strokeWidth="8"></mat-spinner>
    </div>
  }
  <ng-container class="flex-1 overflow-auto">
    <mat-table
      (wheel)="handleScroll($event)"
      cdkDropList
      cdkDropListSortingDisabled
      matSort
      [dataSource]="dataSource"
      (matSortChange)="sortChanged($event)"
      class="mat-elevation-z8 nodes-table">
      <ng-container matColumnDef="address">
        <mat-header-cell class="mat-column-header center in-column"  *matHeaderCellDef>
          <div>Address</div>
          <div>[ Type ]</div>
        </mat-header-cell>
        <mat-cell class="mat-row-cell node-address node-type center in-column" *matCellDef="let nodeInfo">
          <div [attr.data-cy]="'small-unmapped-node-address-' + (nodeInfo.address || '').toString(16)">
            {{ (nodeInfo.address || '').toString(16) }} </div>
          <div style="font-weight: bold;" [attr.data-cy]="'small-unmapped-node-type-' + (nodeInfo.address || '').toString(16)">
            {{ getNodeTypeText(nodeInfo) }} </div></mat-cell>
      </ng-container>
      <ng-container matColumnDef="gatewayName">
        <mat-header-cell
          mat-sort-header
          sortActionDescription="Sort by Gateway Name"
          class="mat-column-header center"
          *matHeaderCellDef>
          Gateway Name
        </mat-header-cell>
        <mat-cell class="mat-row-cell gateway-name center" *matCellDef="let nodeInfo">{{ nodeInfo.gatewayName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="draggableNode">
        <mat-header-cell class='mat-column-header center in-column' *matHeaderCellDef>
          <div>Blink</div>
          <div>[ Node ]</div>
        </mat-header-cell>
        <mat-cell class='mat-row-cell center in-column' *matCellDef="let nodeInfo">
          <button
            mat-mini-fab
            class="or-button or-primary blink-button"
            [disabled]="isBlinkDisabled(nodeInfo)"
            (click)="blinkClicked(nodeInfo)"
            [attr.data-cy]="'small-unmapped-node-blink-button-' + (nodeInfo.address || '').toString(16)">
            <span class="or-icon or-small or-icon-light-level"></span>
          </button>
          <div class="icon-container" [ngClass]="getContainerClass(nodeInfo)"
               cdkDrag
               [cdkDragDisabled]="isNodeDisabled(nodeInfo)"
               [cdkDragData]="nodeInfo">
            <span
              [attr.data-cy]="'small-unmapped-draggable-node-' + (nodeInfo.address || '').toString(16)"
              [ngClass]='getIconClass(nodeInfo)'
              class='or-icon or-tiny icon-span'
            ></span>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="shortenedTableColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: shortenedTableColumns"
               [matTooltip]="getTooltipText(row)"
               [matTooltipPosition]="'right'"
               [matTooltipDisabled]="!isNodeDisabled(row)"
               [ngClass]="getRowClass(row)">
      </mat-row>
    </mat-table>
  </ng-container>
</div>