import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SensorNode } from '@app/shared/models/sensor-node';
import { NavigationService } from '@services/navigation/navigation.service';
import { HoneycombGridProvider } from '@components/heatmap/Grid';
import * as d3 from 'd3';
import { Style } from '@components/floorplan/style';
import { NgStyle } from '@angular/common';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject, Subscription, timer } from 'rxjs';
import { QueryExecutorService } from '@app/analytics/metric-widget/query/query-executor.service';
import { GridRenderer } from '@components/heatmap/renderer/grid-renderer';
import { ColoringRenderer } from '@components/heatmap/renderer/coloring-renderer';
import { Hexagon, ShapesRenderer } from '@components/heatmap/renderer/shapes-renderer';
import { DataInterpolator } from '@components/heatmap/interpolator/data-interpolator';
import { PolynomialInterpolationFunction } from '@components/heatmap/interpolator/interpolation-function';
import { AutomaticInterpolationRange } from '@components/heatmap/interpolator/interpolation-range';
import { HeatmapDataPoint } from '@components/heatmap/renderer/heatmap-renderer';
import { HeatmapJsGradient } from '@components/heatmap/renderer/gradient';
import { FloorplanService } from '@services/floorplan.service';
import { QueryResult } from '@app/analytics/metric-widget/data-objects/query-result';
import { SecurityService } from '@services/security.service';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';

@Component({
  selector: 'app-heatmap',
  imports: [NgStyle],
  providers: [
    {
      provide: GridRenderer,
      useValue: new GridRenderer(
        new ColoringRenderer(
          new ShapesRenderer(new Hexagon()),
          0.0,
          1.0,
          new HeatmapJsGradient(HeatmapJsGradient.DEFAULT_GRADIENT)
        ),
        new HoneycombGridProvider(20, 20000, HoneycombGridProvider.Y_OFFSET_HEXAGON_MULTIPLIER),
        (radius: number) =>
          new DataInterpolator(radius, new PolynomialInterpolationFunction(5.0), new AutomaticInterpolationRange(1.3))
      )
    }
  ],
  templateUrl: './heatmap.component.html',
  styleUrl: './heatmap.component.scss'
})
export class HeatmapComponent implements OnInit, OnDestroy {
  @Input()
  nodes: SensorNode[];

  @Input({ required: true })
  buildingId: number;

  @Input()
  public width: number;

  @Input()
  public height: number;

  @Input()
  style: Style;

  private destroy$ = new Subject<void>();
  private dataSubscription: Subscription;
  private metricContextDataSubscription: Subscription;

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private container: d3.Selection<any, any, any, any>;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly gridRenderer: GridRenderer,
    private metricsService: AnalyticsMetricsService,
    private queryExecutor: QueryExecutorService,
    private floorplanService: FloorplanService,
    private securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.container = d3.select('.or-floorplan-heatmap');
    const containerElement: any = this.container.node();
    this.canvas = document.createElement('canvas');
    this.canvas.style.cssText =
      'position:absolute;left:0;top:0;filter:blur(20px)brightness(1.7)saturate(1.3)contrast(1.3);';
    this.ctx = this.canvas.getContext('2d', {
      willReadFrequently: true,
      alpha: true
    });
    containerElement.style.position = 'absolute';
    containerElement.appendChild(this.canvas);
    this.updateSize();
    this.render();
    this.rerenderContextChanges();
    this.floorplanService.scaleNormalized$.subscribe(() => {
      this.render();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateSize(): void {
    /*
     * Doing this check to work around an issue where somehow assigning to the canvas dimensions would make the
     * heatmap disappear...
     */
    // tslint:disable-next-line:triple-equals
    if (this.canvas.width != this.width || this.canvas.height != this.height) {
      this.container.style('width', this.width);
      this.container.style('height', this.height);
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      this.render();
    }
  }

  private rerenderContextChanges(): void {
    this.subscribeLiveMode();
  }

  subscribeLiveMode(): void {
    combineLatest([
      this.floorplanService.liveModeEnabled$,
      this.securityService.isAuthorizedForBuilding(
        BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS.value,
        this.buildingId
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isLiveMode, hasPermission]) => {
        if (isLiveMode) {
          this.metricContextDataSubscription?.unsubscribe();
          if (hasPermission) {
            this.dataSubscription = timer(0, 5000)
              .pipe(switchMap((_) => this.queryExecutor.doLiveQuery(this.metricsService.getLiveOutline())))
              .subscribe((data) => {
                this.calculateAndRender(data);
              });
          }
        } else {
          this.dataSubscription?.unsubscribe();
          this.subscribeMetricContext();
        }
      });
  }

  subscribeMetricContext(): void {
    if (!this.floorplanService.isLiveModeEnabled) {
      this.metricContextDataSubscription = this.metricsService.currentContext$
        .pipe(
          takeUntil(this.destroy$),
          switchMap((_) => this.queryExecutor.doComplexQuery(this.metricsService.getMainOutline()))
        )
        .subscribe((data) => {
          this.calculateAndRender(data);
        });
    }
  }

  calculateAndRender(data: QueryResult<number>): void {
    const valueMap = new Map<number, number>();
    if (data && data.values.length > 0) {
      data.values.forEach((points) => valueMap.set(points.key, points.value));
    }
    this.nodes.forEach((node) => {
      if (valueMap.size > 0) {
        node.max = data.max;
        node.valueSuffix = data.suffix;
        node.value = valueMap.get(node.address) || 0;
      } else {
        node.value = 0;
      }
    });
    this.updateSize();
    this.render();
  }

  public render(): void {
    if (this.nodes != null) {
      const dataType = this.metricsService.getCurrentContextDataType();
      const data =
        this.nodes.filter((node) => {
          const isDataTypeAvailableForNodeType = dataType.nodeTypes.includes(node.nodeType);
          if (node.value == null) {
            node.value = 0;
          }
          return isDataTypeAvailableForNodeType;
        }) || [];
      const max = this.floorplanService.isScaleNormalized
        ? this.getMaximumValue(this.nodes)
        : this.getMaxScale(this.nodes);
      this.forceRender(data, max);
    }
  }

  private forceRender(data: HeatmapDataPoint[], max: number): void {
    this.ctx.clearRect(0, 0, this.width, this.height);
    if (this.navigationService.getActiveSection().info.Id === 'heatmap') {
      this.gridRenderer.render(data, this.canvas, this.ctx, max, this.width, this.height);
    }
  }

  private getMaximumValue(nodes: SensorNode[]): number {
    let maximum = 0;
    for (const element of nodes) {
      if (element.value != null) {
        maximum = Math.max(element.value, maximum);
      }
    }
    return maximum;
  }

  private getMaxScale(nodes: SensorNode[]): number {
    return nodes[0]?.max ? nodes[0].max : 100;
  }
}
